<template>
  <v-dialog persistent v-model="open" width="950" attach="#main-container">
    <v-card class="br-6 light">
      <v-card-text>
        <v-row class="pl-10 pt-10">
          <v-col class="d-flex w-100 justify-space-between align-center">
            <span class="h5 semiBold w-100 bg-title pa-2"
              >Solicitar descuento</span
            >
            <v-btn icon @click="$emit('close')" small class="ml-3">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <span class="p semiBold title-color">Datos de la cotización</span>
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Cotización N°</span>
                  <span class="p semiBold py-2">Rut</span>
                  <span class="p semiBold py-2">Nombre</span>
                </div>
                <div
                  class="d-flex flex-column px-4 py-3"
                  v-if="resume?.asegurado"
                >
                  <span class="p py-2">{{
                    resume?.numero_cotizacion ?? ""
                  }}</span>
                  <span class="p py-2">{{
                    resume?.asegurado?.numero_documento ?? "" | rutFormat
                  }}</span>
                  <span class="p py-2"
                    >{{ resume?.asegurado?.nombres ?? "" }}
                    {{ resume?.asegurado?.primer_apellido ?? "" }}
                    {{ resume?.asegurado?.segundo_apellido ?? "" }}</span
                  >
                </div>
                <div v-else class="d-flex flex-column px-4 py-3">
                  <span class="p py-2" v-for="index in 3" :key="index">
                    <v-skeleton-loader type="text"></v-skeleton-loader>
                  </span>
                </div>
              </div>
              <div class="d-flex w-50">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Plan</span>
                  <span class="p semiBold py-2">Compañía</span>
                  <span class="p semiBold py-2">Cuota</span>
                </div>
                <div
                  class="d-flex flex-column px-4 py-3"
                  v-if="resume?.monedas"
                >
                  <span class="p py-2">{{
                    resume?.producto?.descripcion
                  }}</span>
                  <span class="p py-2">
                    <v-img :src="logo" width="50" />
                  </span>
                  <span class="p py-2">
                    {{ resume?.monedas?.moneda_local?.simbolo }}
                    {{ resume?.monedas?.moneda_local?.precio | normalFormat }}
                    <span class="px-2">|</span>
                    {{ resume?.monedas?.moneda_seguro?.simbolo }}
                    {{ resume?.monedas?.moneda_seguro?.precio | normalFormat }}
                  </span>
                </div>
                <div v-else class="d-flex flex-column px-4 py-3">
                  <span class="p py-2" v-for="index in 3" :key="index">
                    <v-skeleton-loader type="text"></v-skeleton-loader>
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12">
            <span class="p semiBold title-color">Datos del vendedor</span>
            <div class="d-flex border-table mt-3">
              <div class="d-flex w-100">
                <div class="d-flex flex-column bg-title px-4 py-3">
                  <span class="p semiBold py-2">Rut</span>
                  <span class="p semiBold py-2">Nombre</span>
                  <span class="p semiBold py-2">Concesionario</span>
                  <span class="p semiBold py-2">Sucursal</span>
                  <span class="p semiBold py-2">Convenio</span>
                </div>
                <div
                  class="d-flex flex-column px-4 py-3"
                  v-if="resume?.vendedor"
                >
                  <span class="p py-2">{{
                    resume?.vendedor?.numero_documento | rutFormat
                  }}</span>
                  <span class="p py-2">{{
                    resume?.vendedor?.vendedor_descripcion
                  }}</span>
                  <span class="p py-2">{{
                    resume?.vendedor?.concesionario_descripcion
                  }}</span>
                  <span class="p py-2">{{
                    resume?.vendedor?.sucursal_descripcion
                  }}</span>
                  <span class="p py-2">{{
                    resume?.vendedor?.convenio_descripcion
                  }}</span>
                </div>
                <div v-else class="d-flex flex-column px-4 py-3">
                  <span class="p py-2">
                    <v-skeleton-loader
                      class="mx-auto"
                      width="300"
                      type="text"
                    ></v-skeleton-loader>
                  </span>
                  <span class="p py-2">
                    <v-skeleton-loader
                      class="mx-auto"
                      width="300"
                      type="text"
                    ></v-skeleton-loader>
                  </span>
                  <span class="p py-2">
                    <v-skeleton-loader
                      class="mx-auto"
                      width="300"
                      type="text"
                    ></v-skeleton-loader>
                  </span>
                  <span class="p py-2">
                    <v-skeleton-loader
                      class="mx-auto"
                      width="300"
                      type="text"
                    ></v-skeleton-loader>
                  </span>
                  <span class="p py-2">
                    <v-skeleton-loader
                      class="mx-auto"
                      width="300"
                      type="text"
                    ></v-skeleton-loader>
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10">
          <v-col cols="12" md="6">
            <v-form>
              <v-text-field
                v-model="form.descuento"
                @input="form.descuento = $event"
                outlined
                @blur="calculate"
                :rules="[rules.maxDiscount]"
                label="Descuento a solicitar (%)"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="12" md="6">
            <v-form>
              <v-select
                :items="cargo.answers"
                v-model="form.cargo"
                outlined
                item-text="descripcion"
                item-value="valor"
                return-object
                append-icon="mdi-chevron-down"
                :loading="!cargoLoaded"
                :label="cargo.label"
              ></v-select>
            </v-form>
          </v-col>
          <v-col cols="12" md="6">
            <v-form>
              <v-text-field
                v-model="form.cuota_uf"
                @input="form.cuota_ud = $event"
                outlined
                disabled
                :label="`Cuota con descuento incluido ${resume?.monedas?.moneda_seguro?.simbolo}`"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="12" md="6">
            <v-form>
              <v-select
                :items="motivo.answers"
                v-model="form.motivo"
                outlined
                item-text="descripcion"
                item-value="valor"
                return-object
                append-icon="mdi-chevron-down"
                :loading="!cargoLoaded"
                :label="motivo.label"
              ></v-select>
            </v-form>
          </v-col>
          <v-col cols="12" md="6">
            <v-form>
              <v-text-field
                v-model="form.cuota_peso"
                @input="form.cuota_peso = $event"
                outlined
                disabled
                :label="`Cuota con descuento incluido ${resume?.monedas?.moneda_local?.simbolo}`"
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                rounded
                outlined
                color="primary "
                width="180"
                class="text-none"
                @click="$emit('close')"
                >Cancelar</v-btn
              >
              <v-btn
                rounded
                @click="submit"
                color="primary"
                width="180"
                class="text-none ml-4"
                :loading="loading"
                :disabled="!validForm"
                >Solicitar descuento</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";

export default {
  props: ["toggle", "data"],
  mixins: [LogoRequestMixin],
  data() {
    return {
      open: true,
      validForm: false,
      loading: false,
      cargoLoaded: false,
      resume: {},
      discountRes: {},
      form: {
        descuento: "0",
        cargo: "",
        cuota_uf: "",
        motivo: "",
        cuota_peso: "",
      },
      cargo: {
        label: "Cargo descuento",
        answers: [],
        selected: "",
        rules: [(v) => !!v || "Campo requerido"],
      },
      motivo: {
        label: "Motivo",
        answers: [],
        selected: "",
        rules: [(v) => !!v || "Campo requerido"],
      },
      rules: {
        required: (value) => !!value || "Campo requerido.",
        maxDiscount: (value) => value <= 40 || "Descuento máximo de 40%",
      },
      logo: "",
    };
  },
  methods: {
    ...mapActions("Discounts", [
      "getResume",
      "getCargosMotivos",
      "createDiscount",
      "calculateDiscount",
    ]),
    async getLogo() {
      const resume = await this.getResume(this.data?.numero_cotizacion).catch(
        (error) => {
          this.snackbar(error);
        }
      );
      return axios
        .get(resume?.compania?.logo)
        .then((res) => {
          return res?.data?.data?.url;
        })
        .catch((error) => {
          this.snackbar(error);
        });
    },
    async calculate() {
      const discount = await this.calculateDiscount({
        tipo_descuento: "PORCENTUAL",
        monto_descuento: this.form.descuento,
        monto_seguro:
          this.data?.tarifa_seleccionada.moneda_seguro?.precio ||
          this.data?.tarifa_seleccionada.prima_mensual_seguro,
        moneda_seguro: this.resume?.monedas?.moneda_seguro?.codigo,
        moneda_local: this.resume?.monedas?.moneda_local?.codigo,
      }).catch((error) => {
        this.snackbar(error);
      });
      this.form.cuota_uf = discount.nuevo_monto_seguro.toString();
      this.form.cuota_peso = discount.nuevo_monto_seguro_local.toString();
    },
    async submit() {
      this.loading = true;
      const descuento = {
        cotizacion: this.data?.numero_cotizacion,
        plan: {
          codigo: this.resume?.plan?.codigo,
          descripcion: this.resume?.plan?.descripcion,
        },
        cargo: {
          codigo: this.form?.cargo?.valor,
          descripcion: this.form?.cargo?.descripcion,
        },
        motivo: {
          codigo: this.form?.motivo?.valor,
          motivo: this.form?.motivo?.descripcion,
        },
        producto_descripcion: this.resume?.producto?.descripcion,
        tipo_descuento: "PORCENTUAL",
        monto_descuento: this.form?.descuento,
        asegurado: this.resume?.asegurado,
        vendedor: {
          tipo_documento: this.resume?.vendedor?.tipo_documento,
          numero_documento: this.resume?.vendedor?.numero_documento,
          nombre: this.resume?.vendedor?.vendedor,
        },
        concesionario: {
          id: this.resume?.vendedor?.concesionario,
          descripcion: this.resume?.vendedor?.concesionario_descripcion,
        },
        sucursal: {
          id: this.resume?.vendedor?.sucursal,
          descripcion: this.resume?.vendedor?.sucursal_descripcion,
        },
        convenio: {
          id: this.resume?.vendedor?.convenio,
          descripcion: this.resume?.vendedor?.convenio_descripcion,
        },
        moneda_seguro: {
          ...this.resume?.monedas?.moneda_seguro,
          precio_descuento: this.form?.cuota_uf,
        },
        moneda_local: {
          ...this.resume?.monedas?.moneda_local,
          precio_descuento: this.form?.cuota_peso,
        },
      };
      const cuotas = {
        cuota_uf: this.form.cuota_uf,
        cuota_peso: this.form.cuota_peso,
      };
      await this.createDiscount(descuento)
        .then((res) => {
          this.discountRes = res;
        })
        .catch((error) => {
          this.snackbar(error.response.data.message, "error");
        });
      const emitModal = {
        descuento: descuento,
        cuotas: cuotas,
        res: this.discountRes,
      };
      this.$emit("handleSubmit", emitModal, 1);
      this.loading = false;
    },
    snackbar(message = "", type = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: message,
        top: true,
        right: true,
        color: type,
      });
    },
  },
  async mounted() {
    this.logo = await this.getLogo();
    const cm = await this.getCargosMotivos();
    this.cargo.answers = cm.cargos;
    this.motivo.answers = cm.motivos;
    this.cargoLoaded = true;
    this.resume = await this.getResume(this.data?.numero_cotizacion);
  },
  watch: {
    form: {
      handler(val) {
        const validations = [];
        const descuento = this.rules.required(val.descuento);
        const cargo = this.rules.required(val.cargo);
        const motivo = this.rules.required(val.motivo);
        const maxDescuento = this.rules.maxDiscount(val.descuento);

        validations.push(descuento, cargo, motivo, maxDescuento);
        this.validForm = validations.every(
          (val) => typeof val === "boolean" && val
        );
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("Proposal", ["getProposalData"]),

    cotizacion() {
      return this.getProposalData.cotizacion;
    },
  },
};
</script>
<style scoped>
/* @import "../../assets/CSS/ServiceStyle.css"; */

.bg-title {
  background-color: #e3eeff !important;
}
.border-table {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
</style>
